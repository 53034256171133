import React from 'react'
// import { Link, graphql } from 'gatsby'
import { ModelViewer as Model3Viewer } from 'react-3d-model-viewer'

export default class ModelViewer extends React.Component {

  render() {
    return (
      <Model3Viewer
        url={this.props.url}
        color={this.props.color || '#9c897d'}
        backgroundColor={this.props.backgroundcolor || '#e9e4e2'}
        width={this.props.width || '100%'}
        aspectRatio={this.props.aspectratio || '56.125%'}
        rotate={(this.props.rotate == 'true')}
        initControlPosition={[
          // Number(this.props.rotateleft) || Math.PI/8,
          // Number(this.props.rotateup) || -Math.PI/32,
          Number(this.props.rotateleft) || 0,
          Number(this.props.rotateup) || 0,
          Number(this.props.zoom) || 1.0 
        ]}
      >
      </Model3Viewer>
    )
  }
}