import React from 'react'
import { css } from '@emotion/core'
import { rhythm } from '../utils/typography'
import ReCAPTCHA from "react-google-recaptcha"

class CommentForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      'options[slug]': this.props.slug,
      'fields[comment]': '',
      'fields[name]': '',
      submitMsg: '' 
    }

    //    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange(e) {
    // const name = e.target.name.match(/fields\[(\w+)\]/)[1]
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    const formData = new FormData(this.form)
    const urlEncData = new URLSearchParams(formData)
    this.setState({ submitMsg: 'Submitted...' })
    // const data = new URLSearchParams()
    // data.append("options[slug]", 'test')
    // data.append("fields[name]", 'test')
    // data.append("fields[comment]", 'test')
    // data.append("fields[email]", 'test')
    fetch(
      'https://werksbot.appspot.com/v3/entry/gitlab/makewerks/werkslog/master/comments',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: urlEncData,
      }
    )
      // .then(function(res) { return res.json() })
      //.then(function(data) { alert( JSON.stringify( data )) })
      .then(res => res.json())
      // .then( data => alert(JSON.stringify(data, null, "\t")))
      .then(data => {
        if (!data.success) {
          this.setState({ submitMsg: `Submission failed: ${data.errorCode} - ${data.data}` })
          setTimeout(() => { this.setState({ submitMsg: ''}) }, 5000)
        } else {
          this.setState({ submitMsg: `Thanks for your comment! It will be posted after it's reviewed.` })
          setTimeout(() => { this.setState({
            'fields[comment]': '',
            'fields[name]': '',
            submitMsg: ''
          }) }, 5000)
        }
      })
  }

  render() {
    return (
      <div>
        <form ref={el => (this.form = el)} onSubmit={e => this.handleSubmit(e)}>
          <input name="fields[slug]" type="hidden" value={this.props.slug} />
          <input name="options[reCaptcha][siteKey]" type="hidden" value="6LcaHY4UAAAAAJ1puBprrsv161-MDq7GpYiZ4ORa" />
          <input name="options[reCaptcha][secret]" type="hidden" value="yA6kN6HwUEwoOWukltGh8LkA2mBWLITPrrjZ2Gj9AGsusdGkgAW/d1XGdMC4XQAYxoLiVAgHYQuXWCTLfhrpkAJyW21J0WTeXlXGzU0z2bStHkb2wZFwK0pmHN+cj9GbTWWsPFhB7utjCn7hQsWULTksmltE7JuJkl6RjPgth8bNZIZ8rfOBUNIz6qFwJ7ja8Ng+iyA4Sr4MpGgYJmoODXnt3+boqiM4DLhtIpTMuITb/o8ZTj9nRLYcRGBmjHG0H+OnD4RO4XWITpNX7XBqFHrVhpEJp/zVQZI7PujEOWT+IhC27WXciEOIUmvVJplvNph7WTUc3nerKo4gLd+AtA==" />
          <input
            name="fields[name]"
            type="text"
            placeholder="Name"
            value={this.state['fields[name]']}
            onChange={e => this.handleInputChange(e)}
            required
            css={css`
              width: 100%;
            `}
          />
          <textarea
            name="fields[comment]"
            placeholder="Comment"
            value={this.state['fields[comment]']}
            onChange={e => this.handleInputChange(e)}
            required
            css={css`
              width: 100%;
              margin: ${rhythm(0.8)} 0;
            `}
          />
          <ReCAPTCHA
            sitekey="6LcaHY4UAAAAAJ1puBprrsv161-MDq7GpYiZ4ORa"
            onChange={(value) => console.log(`Captcha value: ${value}`)}
          />
          <div
            css={css`
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: flex-start;
              align-items: center;
              margin-top: ${rhythm(0.8)};
            `}
          >
            <button type="submit">Submit</button>
            <div
              id='sub-text'
              css={css`
                margin-left: ${rhythm(0.4)};
              `}
            >
              {this.state.submitMsg}
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default CommentForm
