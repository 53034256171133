import React from 'react'
import { Link, graphql } from 'gatsby'
import { rhythm, scale } from '../utils/typography'
import { css } from '@emotion/core'
import rehypeReact from 'rehype-react'
import ModelViewer from '../components/ModelViewer'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import CommentForm from '../components/CommentForm'

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    'model-viewer': ModelViewer
  }
}).Compiler

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const comments = this.props.data.allCommentsYaml && this.props.data.allCommentsYaml.edges


    let commentsList = <div>No comments</div>
    if (comments && comments.length) {
      commentsList = comments.map(comment => (
        <div key={comment.node.id}>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              font-size: ${rhythm(0.5)};
              color: #917c6f80;
            `}
          >
            <div> {comment.node.name} </div>
            <div> {comment.node.date} </div>
          </div>
          <div
            css={css`
              margin-bottom: ${rhythm(0.5)};
            `}
          >
            {comment.node.comment}
          </div>
        </div>
      ))
    }


    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <h1
          css={css`
            margin-bottom: 0;
          `}
        >
          {post.frontmatter.title}
        </h1>
        <div>
          {post.frontmatter.date}
        </div>
        <Img
          sizes={post.frontmatter.featuredImage.childImageSharp.sizes}
          css={css`
            margin-top: ${rhythm(1)};
            margin-bottom: ${rhythm(1)};
          `}
        />
        {/* <div dangerouslySetInnerHTML={{ __html: post.html }} /> */}
        <div>
        {
          renderAst(post.htmlAst)
        }
        </div>
        <hr
          css={css`
            margin-bottom: ${rhythm(1)};
          `}
        />
        <Bio />

        <div>
          <hr />
          <h3>Comments</h3>
          <div>{commentsList}</div>
        </div>

        <h4>Add a comment</h4>

        <CommentForm slug={this.props.pageContext.slug} />

        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: ${rhythm(1.0)};
          `}
        >
          <span css={css``}>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </span>
          <span>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </span>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      htmlAst
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 630) {
              ...GatsbyImageSharpSizes_tracedSVG
            }
          }
        }
      }
    }
    allCommentsYaml(
      filter: { slug: { eq: $slug } }
      sort: {fields: [date], order: DESC}
    ) {
      edges {
        node {
            id
            name
            comment
            dts: date
            date(formatString: "dddd DD MMMM YYYY")
        }
      }
    }
  }
`
